jQuery.fn.InitializeSimpleSearch = function (option) {
    var defaults = {
        siteSearchUrl: null
    };
    var option = $.extend({}, defaults, option);
    var pageContent = $(this);
    var $input = pageContent.find(".js-typeahead-search .text-input"),
        $button = pageContent.find(".js-text-input-submit");

    $button.on('click tap', function () {
        SiteSearch();
    });

    $input.keypress(function (event) {
        var keyCode = (event.which ? event.which : event.keyCode);
        if (keyCode === 13) {
            SiteSearch();
            return false;
        }
        return true;
    });

    function SiteSearch() {
        var siteSearchUrl = option.siteSearchUrl + "?qu=" + encodeURIComponent($input.val());
        window.location.href = siteSearchUrl;
    }
};